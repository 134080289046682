import axios from 'axios';
import React from 'react'

function Register() {

    const Register = (e) => {
        e.preventDefault();

        axios.post(`/api/create`).then(res => {
            if(res.data.status === 200){
                
            }
        }).catch((error) => {
            if(error.response.status === 500){

            }
        })
    }

    return (
        <div>
            <form onSubmit={Register}>
                <input type="text" />
                <input type="password" name="" id="" />
                <button>Submit</button>
            </form>
        </div>
    )
}

export default Register